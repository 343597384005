@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-Semibold'), url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Regattia-Bold';
  src: local('Regattia-Bold'), url(./assets/fonts/Regattia-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: local('Gotham-Bold'), url(./assets/fonts/Gotham-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: local('Gotham-Medium'), url(./assets/fonts/Gotham-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Regular';
  src: local('Gotham-Regular'), url(./assets/fonts/Gotham-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Light';
  src: local('Gotham-Light'), url(./assets/fonts/Gotham-Light.otf) format('opentype');
}
html,
body,
#root {
  position: fixed;
  overflow: hidden;
  height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  width: 100%;
  -webkit-text-size-adjust: none;
}
